import React, { useEffect, useState } from "react";
import "./categories.css";
import { Link } from "react-router-dom";
import { handle_getcelebrate_Image_by_admin } from "../../Redux/action";
import { useDispatch, useSelector } from "react-redux";
const Categories = () => {
  const [images, setimages] = useState([]);
  const { city } = useSelector((store) => store);
    const dispatch = useDispatch();

    useEffect(() => {
     dispatch(handle_getcelebrate_Image_by_admin()).then((res) => {
       setimages(res?.data?.data);
     });
  },[])
  // console.log("img", images)
  return (
    <div className="cont-cat">
      {images?.map((el) => (
        <div>
          <Link to={`products?city=${city}&category=${el?.category}`}>
            <img
              src={el?.images}
              alt=""
            />
          </Link>
        </div>
      ))}

      {/* <div>
        <Link to={`products?city=${city}&category=occasions`}>
          <img
            src="https://valentinesagasssets.s3.amazonaws.com/WebSiteBanner/Haldi.webp"
            alt=""
          />
        </Link>
      </div>
      <div>
        <Link to={`products?city=${city}&category=candlelightdinner`}>
          <img
            src="https://valentinesagasssets.s3.amazonaws.com/WebSiteBanner/CandleLight.webp"
            alt=""
          />
        </Link>
      </div>
      <div className="cont-cat-3">
        <Link to={`products?city=${city}&category=decorations`}>
          <img
            src="https://valentinesagasssets.s3.amazonaws.com/WebSiteBanner/Decoration.webp"
            alt=""
          />
        </Link>
      </div> */}
    </div>
  );
};

export default Categories;
