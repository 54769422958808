import {
  Box,
  Center,
  Heading,
  Text,
  Button,
  Image,
  Flex,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Thankyou = () => {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(timer);
        navigate("/");
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [seconds, navigate]);

  return (
    <Center h="90vh">
      <Box
        p={8}
        bg="white"
        boxShadow="lg"
        rounded="lg"
        maxW="600px"
        w="90%"
        textAlign="center"
      >
        <Flex alignItems={"center"} justifyContent={"center"}>
          <Image
            src="https://media.tenor.com/40bYuytgRvYAAAAi/hedge-pay-hpay.gif"
            alt="Error"
            mb={6}
          />
        </Flex>
        <Box mt={"-15%"} textAlign={"center"}>
          <Heading color="green.500" size="lg" textAlign="center">
            Thank You{" "}
          </Heading>
          <Text mt={4} textAlign={"center"}>
            Your payment was successful. Thank you for your order!
          </Text>
          <Text mt={4} textAlign={"center"}>
            Redirecting in {seconds} seconds...
          </Text>
          <Button
            colorScheme="blue"
            variant="solid"
            size="md"
            mt={6}
            onClick={() => {
              navigate("/");
            }}
          >
            Go back to home
          </Button>
        </Box>
      </Box>
    </Center>
  );
};

export default Thankyou;
