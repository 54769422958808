import React from "react";
import { Button, HStack } from "@chakra-ui/react";

const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  return (
    <HStack spacing={2} justifyContent="center">
      {pageNumbers.map((page) => (
        <Button
          key={page}
          variant={page === currentPage ? "solid" : "outline"}
          colorScheme="teal"
          onClick={() => onPageChange(page)}
        >
          {page}
        </Button>
      ))}
    </HStack>
  );
};

export default CustomPagination;
