import React, { useRef } from "react";
// import './AddressPage.css';
import "../Styles/shipping.css";
import { useDispatch } from "react-redux";
import { handleaddress } from "../Redux/action";
import { toast } from "react-toastify";
function AddressPage({ handlesavaddress }) {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("userdetails"));
  const userdet = useRef({});
  const handle_address_update = (e) => {
    e.preventDefault();
    let Shipping_name = userdet.current["name"].value;
    let houseno = userdet.current["houseno"].value;
    let street = userdet.current["street"].value;
    let city = userdet.current["city"].value;
    let state = userdet.current["state"].value;
    let postalcode = userdet.current["postalcode"].value;
    let mobileno = userdet.current["mobileno"].value;

    postalcode = "" + postalcode;
    let address =
      Shipping_name +
      " , " +
      houseno +
      " , " +
      street +
      " , " +
      city +
      " , " +
      state +
      " , " +
      postalcode +
      " , " +
      mobileno;

    let data = {
      Shipping_name: Shipping_name,
      houseno: houseno,
      street: street,
      city,
      state,
      postalcode,
      mobileno: mobileno,
    };

    let obj = {
      address: address,
      Shipping_name: Shipping_name,
      houseno: houseno,
      street: street,
      city: city,
      state: state,
      postalcode: postalcode,
      mobileno: mobileno,
    };

    dispatch(handleaddress(user?._id, obj)).then((res) => {
      if (res.status === 201 || res.status == 200) {
        localStorage.setItem("address", JSON.stringify(data));
        toast.success("Address Saved", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        handlesavaddress();
      } else {
        toast.error("Some Error Please retry", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  return (
    <div className="address-container">
      <h2>Address Information</h2>
      <form action="" onSubmit={handle_address_update}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            placeholder="Enter Your Name"
            ref={(e) => (userdet.current["name"] = e)}
          />
        </div>
        <div>
          <label htmlFor="house">House No.</label>
          <input
            type="text"
            id="house"
            placeholder="Enter Your house No"
            ref={(e) => (userdet.current["houseno"] = e)}
          />
        </div>
        <div>
          <label htmlFor="street">Street</label>
          <input
            type="text"
            id="street"
            placeholder="Enter Your Street"
            ref={(e) => (userdet.current["street"] = e)}
          />
        </div>

        <div>
          <label htmlFor="city">City</label>
          <input
            type="text"
            id="city"
            placeholder="Enter Your City"
            ref={(e) => (userdet.current["city"] = e)}
          />
        </div>
        <div>
          <label htmlFor="state">State</label>
          <input
            type="text"
            id="state"
            placeholder="Enter Your State"
            ref={(e) => (userdet.current["state"] = e)}
          />
        </div>
        <div>
          <label htmlFor="postalCode">Postal Code</label>
          <input
            type="text"
            id="postalCode"
            placeholder="Enter Your Postal Code"
            ref={(e) => (userdet.current["postalcode"] = e)}
          />
        </div>
        <div>
          <label htmlFor="postalCode">Mobile No</label>
          <input
            type="text"
            id="mobileno"
            placeholder="Enter Your Mobile No"
            ref={(e) => (userdet.current["mobileno"] = e)}
          />
        </div>
        <button className="saveadd">Save Address</button>
      </form>
    </div>
  );
}

export default AddressPage;
