import React from 'react'

const Faq = () => {
  return (
    <div>
      faq
    </div>
  )
}

export default Faq
