import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handle_edit_product_by_admin,
  handlegetproducts,
} from "../../Redux/action";
import { MdDelete } from "react-icons/md";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { EditIcon } from "@chakra-ui/icons";

export default function EditModal({ id, products }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [product, setproduct] = React.useState([]);
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const toast = useToast();
  // const [showWeightInput, setShowWeightInput] = React.useState(false);
  // const [weight, setWeight] = React.useState("");
  React.useEffect(() => {
    let findproduct = products?.filter((el) => {
      return el._id === id;
    });
    if (findproduct?.length) {
      setproduct(findproduct[0]);
    }
    // if (product?.category === "cakes") {
    //   setShowWeightInput(true);
    // }
  }, [products]);

  const handle_edit_product = (e) => {
    e.preventDefault();
    let prod_details = document.querySelectorAll(".prod_details");
    let product_details = [];
    for (let i = 0; i < prod_details.length; i++) {
      product_details?.push(prod_details[i]?.value);
    }

    let prod_cat_price = document.querySelectorAll(".prod_cat_price");
    let prod_cat_name = document.querySelectorAll(".prod_cat_name");

    let Product_category = [];
    for (let i = 0; i < prod_cat_name.length; i++) {
      Product_category.push({
        name: prod_cat_name[i].value,
        price: prod_cat_price[i].value,
      });
    }

    let Addons = [];
    let addons_name = document.querySelectorAll(".addons-name");
    let addons_desc = document.querySelectorAll(".addons-desc");
    let addons_img = document.querySelectorAll(".addons-url");
    let addons_price = document.querySelectorAll(".addons-price");

    if (product.addons?.length > 0) {
      for (let i = 0; i < product?.addons?.length; i++) {
        Addons.push({
          name: addons_name[i].value,
          price: addons_price[i].value,
          desc: addons_desc[i].value,
          img: addons_img[i].value,
        });
      }
    }

    let desc = document.querySelectorAll(".description");
    let description = [];
    for (let i = 0; i < desc.length; i++) {
      description?.push(desc[i]?.value);
    }

    let multiple_price_weight = document.querySelectorAll(
      ".multiple_price_weight"
    );
    let multiple_price_price = document.querySelectorAll(
      ".multiple_price_price"
    );

    let multiple_price_arr = [];
    if (multiple_price_price.length > 0 && multiple_price_weight.length > 0) {
      for (let i = 0; i < product?.multiple_price.length; i++) {
        multiple_price_arr?.push({
          weight: multiple_price_weight[i].value,
          price: multiple_price_price[i].value,
        });
      }
      for (let i = 0; i < multiple_price_arr.length; i++) {
        if (
          multiple_price_arr[i].weight == "" ||
          multiple_price_arr[i].price == ""
        ) {
          multiple_price_arr = [];
          break;
        }
      }
    }

    let delivery_info = document.querySelectorAll(".delivery");
    let Delivery_info = [];
    for (let i = 0; i < delivery_info?.length; i++) {
      Delivery_info?.push(delivery_info[i]?.value);
    }

    let starttime = document.querySelectorAll(".starttime");
    let starttime_words = document.querySelectorAll(".starttime-words");
    let endtime = document.querySelectorAll(".endtime");
    let endtime_words = document.querySelectorAll(".endtime-words");

    let actualslots = [];
    if (
      endtime.length &&
      endtime_words.length &&
      starttime_words.length &&
      starttime.length
    ) {
      for (let i = 0; i < product?.slots?.length; i++) {
        actualslots.push({
          starttime: starttime[i].value,
          starttime_words: starttime_words[i].value,
          endtime_words: endtime_words[i].value,
          endtime: endtime[i].value,
        });
      }
    }

    const url = document.querySelectorAll(".url") || "";
    let imgurls = [];
    for (let i = 0; i < url.length; i++) {
      imgurls.push(url[i].value);
    }

    const name = document.querySelector(".name").value || "";
    const category = document.querySelector(".category").value || "";
    const video_link = document.querySelector(".video_link").value || "";
    const price = document.querySelector(".price").value || "";
    const city = document.querySelector(".city").value || "";
    const pincodes = document.querySelector(".pincodes").value;
    const subcategory = document.querySelector(".subcategory").value || "";

    let obj = {
      _id: product._id,
      name: name,
      category: category,
      subcategory: subcategory,
      price: price,
      city: city,
      image: imgurls,
      prod_details: product_details,
      description: description,
      multiple_price: multiple_price_arr,
      addons: Addons,
      delivery_info: Delivery_info,
      pincodes: pincodes?.split(","),
      Product_category,
      slots: actualslots,
      video_link,
    };
    // console.log(obj);

    dispatch(handle_edit_product_by_admin(product?._id, obj)).then((res) => {
      if (res.status == 200 || res.status == 201) {
        toast({
          title: "Product Updated",
          description: "Success!",
          status: "success",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
      } else {
        alert(`Product Not Updated(error),`);
        toast({
          title: "Product Not Updated",
          description: "failed!",
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
      }
    });
    onClose();
  };

  const handleAddRow = () => {
    setproduct({ ...product, image: [...product.image, ""] });
  };

  const handleImages = (i) => {
    if (i >= 0) {
      const updateimg = [...product.image];
      updateimg.splice(i, 1);
      setproduct({ ...product, image: updateimg });
    } else {
      // Handle the logic when `i` is less than 0
      // For example, you might want to display an error message or handle it differently
    }
  };

  // for addons one more rows

  const handleAddRowadons = () => {
    setproduct({ ...product, addons: [...product.addons, ""] });
  };
  const handleaddonsdelete = (i) => {
    if (i >= 0) {
      const updatesadons = [...product.addons];
      updatesadons.splice(i, 1);
      setproduct({ ...product, addons: updatesadons });
    } else {
      // Handle the logic when `i` is less than 0
      // For example, you might want to display an error message or handle it differently
    }
  };

  // for add slot
  const handleAddslots = () => {
    setproduct({ ...product, slots: [...product.slots, ""] });
  };
  const handleslotdelete = (i) => {
    if (i >= 0) {
      const updateslot = [...product.slots];
      updateslot.splice(i, 1);
      setproduct({ ...product, slots: updateslot });
    } else {
      // Handle the logic when `i` is less than 0
      // For example, you might want to display an error message or handle it differently
    }
  };

  // for add details
  const handleAdddetail = () => {
    setproduct({ ...product, prod_details: [...product.prod_details, ""] });
  };
  const handledetailsd = (ind) => {
    if (ind >= 0) {
      const updatedetails = [...product.prod_details];
      updatedetails.splice(ind, 1);
      setproduct({ ...product, prod_details: updatedetails });
    } else {
      // Handle the logic when `i` is less than 0
      // For example, you might want to display an error message or handle it differently
    }
  };

  // for add description

  const handledesc = () => {
    setproduct({ ...product, description: [...product.description, ""] });
  };
  const handledescd = (ind) => {
    if (ind >= 0) {
      const updatedesc = [...product.description];
      updatedesc.splice(ind, 1);
      setproduct({ ...product, description: updatedesc });
    } else {
    }
  };

  // for delivery add and delete
  const handledeli = () => {
    setproduct({ ...product, delivery_info: [...product.delivery_info, ""] });
  };
  const handledelid = (ind) => {
    if (ind >= 0) {
      const updatedeli = [...product.delivery_info];
      updatedeli.splice(ind, 1);
      setproduct({ ...product, delivery_info: updatedeli });
    } else {
    }
  };

  const handlepricewrows = () => {
    setproduct({
      ...product,
      multiple_price: [...product.multiple_price, ""],
    });
  };
  const handlepricew = (i) => {
    if (i >= 0) {
      const updatemultiple = [...product.multiple_price];
      updatemultiple.splice(i, 1);
      setproduct({ ...product, multiple_price: updatemultiple });
    } else {
    }
  };
  // console.log("edit", product);
  // const handleshowweight = (event) => {
  //   const selectedCategory = event.target.value;
  //   if (selectedCategory === "cakes") {
  //     setShowWeightInput(true);
  //   } else {
  //     setShowWeightInput(false);
  //     setWeight("");
  //   }
  // };
  return (
    <div style={{ width: "80%" }}>
      <Button backgroundColor={"green.200"} onClick={onOpen}>
        <EditIcon color="green" fontSize={"20px"} />
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mt="-10%">
              <div style={{ height: "80vh", overflowY: "auto" }}>
                <label htmlFor="">Name</label>
                <input
                  type="text"
                  contentEditable={true}
                  className="name"
                  placeholder="Edit Name of Product"
                  defaultValue={product?.name}
                />
                <label htmlFor="">category</label>
                <select
                  className="category"
                  // onChange={handleshowweight}
                  defaultValue={product?.category}
                >
                  <option value="cakes">Cakes</option>
                  <option value="candlelightdinner">Candlelight Dinner</option>
                  <option value="flowers">Flowers</option>
                  <option value="decorations">Decorations</option>
                  <option value="anniversarycelebrations">
                    Anniversary Celebrations
                  </option>
                  <option value="birthdaysurprises">Birthday Surprises</option>
                  <option value="kidscelebrations">Kids Celebrations</option>
                  <option value="roomdecorations">Room Decorations</option>
                  <option value="giftsandsurprises">Gifts And Surprises</option>
                  <option value="occasions">Occasions</option>
                </select>
                {/* {showWeightInput && (
                  <div>
                    <label htmlFor="weight">Weight</label>
                    <input
                      type="text"
                      className="weight"
                      placeholder="Enter Weight"
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                    />
                  </div>
                )} */}
                <label htmlFor="">image</label>
                {product?.image?.map((el, i) => {
                  return (
                    <Box>
                      <input
                        contentEditable={true}
                        className="url"
                        type="text"
                        placeholder="Edit url of Product"
                        defaultValue={el}
                      />

                      <MdDelete
                        color="red"
                        onClick={() => handleImages(i)}
                        size={"30px"}
                        cursor={"pointer"}
                      />
                    </Box>
                  );
                })}

                <Button ml="10rem" color={"green"} onClick={handleAddRow}>
                  Add One More Row
                </Button>
                <label htmlFor="">Video Link</label>
                <input
                  contentEditable={true}
                  type="text"
                  className="video_link"
                  placeholder="Edit Video Link of Product"
                  defaultValue={product?.video_link}
                />
                {/* <input defaultValue={product?.image[0]} /> */}
                <label htmlFor="">price</label>
                <input
                  contentEditable={true}
                  type="text"
                  className="price"
                  placeholder="Edit price of Product"
                  defaultValue={product?.price}
                />
                <label htmlFor="">Sub Category</label>
                <input
                  contentEditable={true}
                  type="text"
                  className="subcategory"
                  placeholder="Edit Name of Product"
                  defaultValue={product?.subcategory}
                />
                {/* <label htmlFor="">city</label>
                <input
                  contentEditable={true}
                  type="text"
                  className="city"
                  placeholder="Edit Name of Product"
                  defaultValue={product?.city}
                /> */}
                <label htmlFor="city">City</label>
                <select className="city" defaultValue={product?.city}>
                  <option value=" ">Choose city</option>
                  <option value="delhi">Delhi</option>
                  <option value="jaipur">Jaipur</option>
                  <option value="bangalore">Bangalore</option>
                  <option value="kolkata">Kolkata</option>
                  <option value="indore">Indore</option>
                  <option value="pune">Pune</option>
                  <option value="allindia">ALL India</option>
                  <option value="hyderabad">Hyderabad</option>
                  <option value="mumbai">Mumbai</option>
                  <option value="kanpur">Kanpur</option>
                  <option value="chennai">Chennai</option>
                  <option value="jammu">Jammu</option>
                  <option value="lucknow">Lucknow</option>
                  <option value="chandigarh">Chandigarh</option>
                  <option value="hyderabad">Hyderabad</option>
                </select>
                {product?.multiple_price?.length > 0 && (
                  <label htmlFor="">multiple_price</label>
                )}
                {/* {product?.multiple_price?.map((el, i) => {
                  return (
                    <Box>
                      <div
                        style={{ border: "1px solid gray", padding: "10px" }}
                      >
                        <label htmlFor="">Weight</label>
                        <input
                          contentEditable={true}
                          type="text"
                          className="multiple_price_weight"
                          placeholder="Edit Weight"
                          defaultValue={el.weight}
                        />
                        <label htmlFor="">Price</label>
                        <input
                          contentEditable={true}
                          type="text"
                          className="multiple_price_price"
                          placeholder="Edit Price"
                          defaultValue={el.price}
                        />
                      </div>
                      <MdDelete
                        color="red"
                        onClick={() => handlepricew(i)}
                        size={"30px"}
                        cursor={"pointer"}
                      />
                    </Box>
                  );
                })} */}
                {product.multiple_price && product.multiple_price.length > 0 ? (
                  product.multiple_price.map((el, i) => (
                    <Box key={i}>
                      <div
                        style={{ border: "1px solid gray", padding: "10px" }}
                      >
                        <label htmlFor="">Weight</label>
                        <input
                          contentEditable={true}
                          type="text"
                          className="multiple_price_weight"
                          placeholder="Edit Weight"
                          defaultValue={el.weight}
                        />
                        <label htmlFor="">Price</label>
                        <input
                          contentEditable={true}
                          type="text"
                          className="multiple_price_price"
                          placeholder="Edit Price"
                          defaultValue={el.price}
                        />
                      </div>
                      <MdDelete
                        color="red"
                        onClick={() => handlepricew(i)}
                        size={"30px"}
                        cursor={"pointer"}
                      />
                    </Box>
                  ))
                ) : (
                  <div>
                    <Box>
                      <label htmlFor="">Weight</label>
                      <input
                        contentEditable={true}
                        type="text"
                        className="multiple_price_weight"
                        placeholder="Edit Weight"
                      />
                      <label htmlFor="">Price</label>
                      <input
                        contentEditable={true}
                        type="text"
                        className="multiple_price_price"
                        placeholder="Edit Price"
                      />
                    </Box>
                  </div>
                )}

                <Button ml="10rem" color={"green"} onClick={handlepricewrows}>
                  Add more rows
                </Button>

                {product?.booked_dates?.length > 0 && (
                  <label htmlFor="">booked_dates</label>
                )}
                {product?.booked_dates?.map((el) => {
                  return (
                    <input
                      contentEditable={true}
                      type="text"
                      className="booked_dates"
                      placeholder="Edit Booked Dates"
                      defaultValue={el}
                    />
                  );
                })}
                <div>
                  <label htmlFor="">addons</label>

                  {product?.addons?.map((el, i) => {
                    return (
                      <div
                        style={{ border: "1px solid gray", padding: "10px" }}
                      >
                        <label>Name</label>
                        <input
                          contentEditable={true}
                          type="text"
                          className="addons-name"
                          placeholder="addon-name"
                          defaultValue={el.name}
                        />
                        <label>Price</label>
                        <input
                          contentEditable={true}
                          type="text"
                          className="addons-price"
                          placeholder="addon-price"
                          defaultValue={el.price}
                        />
                        <label>Description</label>
                        <input
                          contentEditable={true}
                          type="text"
                          className="addons-desc"
                          placeholder="addon-desc"
                          defaultValue={el.desc}
                        />
                        <label>Url</label>
                        <input
                          contentEditable={true}
                          type="text"
                          className="addons-url"
                          placeholder="Edit Url"
                          defaultValue={el.img}
                        />
                        <hr />
                        <MdDelete
                          color="red"
                          onClick={() => handleaddonsdelete(i)}
                          size={"30px"}
                          cursor={"pointer"}
                        />
                      </div>
                    );
                  })}
                  <Button
                    ml="10rem"
                    color={"green"}
                    onClick={handleAddRowadons}
                  >
                    Add one more row
                  </Button>
                </div>
                <label htmlFor="">Slots</label>
                {product?.slots?.map((el, i) => {
                  return (
                    <>
                      <div>
                        <label htmlFor="">Start Time</label>
                        <select className="starttime">
                          <option defaultValue={el.starttime}>
                            {el.starttime}
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>

                        <select className="starttime-words">
                          <option value={el.starttime_words}>
                            {el.starttime_words}
                          </option>
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </select>
                      </div>
                      <div>
                        <label htmlFor="">End Time</label>
                        <select className="endtime">
                          <option defaultValue={el.endtime}>
                            {el.endtime}
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                        <select className="endtime-words">
                          <option value={el.endtime_words}>
                            {el.endtime_words}
                          </option>
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </select>
                      </div>
                      <MdDelete
                        color="red"
                        onClick={() => handleslotdelete(i)}
                        size={"30px"}
                        cursor={"pointer"}
                      />
                    </>
                  );
                })}
                <Button ml="10rem" color={"green"} onClick={handleAddslots}>
                  Add one more slots
                </Button>
                {/* <input type="text" placeholder='Edit Name of Product' defaultValue={product?.add} /> */}
                <label htmlFor="">prod_details</label>
                {product?.prod_details?.map((el, ind) => {
                  return (
                    <div>
                      <input
                        contentEditable={true}
                        type="text"
                        className="prod_details"
                        placeholder={`Edit line ${ind}`}
                        defaultValue={el}
                      />
                      <MdDelete
                        color="red"
                        onClick={() => handledetailsd(ind)}
                        size={"30px"}
                        cursor={"pointer"}
                      />
                    </div>
                  );
                })}
                <Button ml="10rem" color={"green"} onClick={handleAdddetail}>
                  Add one more details
                </Button>
                <label htmlFor="">description</label>
                {product?.description?.map((el, ind) => {
                  return (
                    <div>
                      <input
                        contentEditable={true}
                        type="text"
                        className="description"
                        placeholder={`Edit line ${ind}`}
                        defaultValue={el}
                      />
                      <MdDelete
                        color="red"
                        onClick={() => handledescd(ind)}
                        size={"30px"}
                        cursor={"pointer"}
                      />
                    </div>
                  );
                })}
                <Button ml="10rem" color={"green"} onClick={handledesc}>
                  Add one description
                </Button>
                <label htmlFor="">delivery_info</label>
                {product?.delivery_info?.map((el, ind) => {
                  return (
                    <div>
                      <input
                        contentEditable={true}
                        type="text"
                        className="delivery"
                        placeholder={`Edit line ${ind}`}
                        defaultValue={el}
                      />
                      <MdDelete
                        color="red"
                        onClick={() => handledelid(ind)}
                        size={"30px"}
                        cursor={"pointer"}
                      />
                    </div>
                  );
                })}
                <Button ml="10rem" color={"green"} onClick={handledeli}>
                  Add one delivery_info
                </Button>
                {/* <input type="text" placeholder='Edit Name of Product' defaultValue={product?.delivery_info[0]}/> */}
                <label htmlFor="">pincodes</label>
                <input
                  contentEditable={true}
                  type="text"
                  className="pincodes"
                  placeholder="Edit Pincodes"
                  defaultValue={product?.pincodes}
                />
                <button
                  style={{
                    background: "blue",
                    color: "white",
                    padding: "10px 25px",
                    display: "block",
                    margin: "auto",
                    cursor: "pointer",
                    border: "none",
                    borderRadius: "10px",
                    marginTop: "20px",
                  }}
                  onClick={handle_edit_product}
                >
                  Save
                </button>
              </div>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
