import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Box,
  Text,
  ModalCloseButton,
  useDisclosure,
  Button,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { handle_get_all_users } from "../../Redux/action";
const Address = ({ id, data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    const single = data.find((el) => el._id === id);

    if (single) {
      setUserId(single.userID);
    }
  }, [id, data]);

  useEffect(() => {
    dispatch(handle_get_all_users()).then((res) => {
      setUsers(res?.data?.data);
    });
  }, []);
  const userfind = users.find((el) => el._id === userId);
  const isAddressNull = !userfind?.address;
  return (
    <>
      <Button onClick={onOpen} color={"tomato"} bg="green.200">
        Show Address
      </Button>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={"2xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Address</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Table mt="-8%" variant="striped" size="sm" colorScheme="gray">
              <Tbody>
                <Tr>
                  <Td fontWeight={"600"}>Name:</Td>
                  <Td fontWeight={"bold"}>{userfind?.name}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={"600"}>Email:</Td>
                  <Td fontWeight={"bold"}> {userfind?.email}</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={"600"}>Address:</Td>
                  <Td color="tomato" fontSize={"15px"} fontWeight={"700"}>
                    {userfind?.address}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default Address;
