import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handle_delete_users_by_admin,
  handle_get_all_users,
  handlegetproducts,
} from "../../Redux/action";
import {
  Button,
  Table,
  TableContainer,
  Td,
  Th,
  Thead,
  Tbody,
  Tr,
} from "@chakra-ui/react";
import CustomPagination from "./CustomPagination";
const TableCompUsers = () => {
  const [users, setUsers] = useState([]);
  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = users.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return users.slice(startIndex, endIndex);
  };

  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  

  useEffect(() => {
    dispatch(handle_get_all_users()).then((res) => {
      setUsers(res?.data?.data);
    });
  }, []);


  let keys = [
    "image",
    // "id",
    "name",
    "email",
    "address",
    "total_visits",
    "total_orders",
    "Delete",
  ];

  const handleDeleteUser = (id) => {
    dispatch(handle_delete_users_by_admin(id)).then((res) => {
      if (res.status === 200 || res.status === 201) {
        alert("User deleted");
      } else {
        alert("Error");
      }
    });
  };

  return (
    <div>
      <TableContainer style={{ minWidth: "100%" }}>
        <Table variant="striped" size={"sm"} colorScheme="gray">
          <Thead bg={"tomato"}>
            <Tr>
              {keys.map((el) => {
                return (
                  <Th color={"white"} key={el}>
                    {el}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {getPaginatedData().map((item) => (
              <Tr key={item._id}>
                <Td>
                  <img
                    style={{ height: "50px", width: "50px" }}
                    src={
                      "https://w7.pngwing.com/pngs/831/88/png-transparent-user-profile-computer-icons-user-interface-mystique-miscellaneous-user-interface-design-smile-thumbnail.png"
                    }
                    alt=""
                  />
                </Td>
                {/* <Td>{item._id}</Td> */}
                <Td>{item.name?.slice(0, 10)}</Td>
                <Td>{item.email}</Td>
                <Td>{item.address}</Td>
                <Td>{0}</Td>
                <Td>{0}</Td>
                <Td>
                  <Button onClick={() => handleDeleteUser(item._id)}>
                    DELETE
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <CustomPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default TableCompUsers;
