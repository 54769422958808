
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handle_delete_product_by_admin,
  handlegetproducts,
  handlegetfilterproductsbyadmin,
} from "../../Redux/action";
import EditModal from "../AdminComponents/ProductEditModal";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Table,
  TableContainer,
  Td,
  Th,
  Thead,
  Tbody,
  Tr,
  Spinner,
  Text,
  Box,
} from "@chakra-ui/react";
import Showmoredetail from "./Showmoredetail";

const TableCompProducts = () => {
  const [cityFilter, setCityFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10); // Number of products per page

  const handleFilter = () => {
    dispatch(handlegetfilterproductsbyadmin(cityFilter, categoryFilter));
    setCurrentPage(1); // Reset to the first page when applying filters
  };

  const handle_reset_filter = () => {
    setCityFilter("");
    setCategoryFilter("");
    dispatch(handlegetproducts());
    setCurrentPage(1); // Reset to the first page when resetting filters
  };

  const handle_delete_product = (id) => {
    dispatch(handle_delete_product_by_admin(id)).then((res) => {
      if (res.status === 200 || res.status === 201) {
        alert("Product deleted successfully");
      } else {
        alert("error");
      }
    });
  };

  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const { loading, error, allproducts } = store;

  useEffect(() => {
    dispatch(handlegetproducts());
  }, []);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = allproducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(allproducts.length / productsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  let keys = ["image", "name", "category", "city", "Price", "Edit", "Delete"];

  return (
    <div>
      {/* ... Your filter controls ... */}
      <Box w="30rem">
        <select
          value={cityFilter}
          onChange={(e) => setCityFilter(e.target.value)}
        >
          <option value="">Select City</option>
          <option value="delhi">Delhi</option>
          <option value="jaipur">Jaipur</option>
          <option value="bangalore">Bangalore</option>
          <option value="kolkata">Kolkata</option>
          <option value="indore">Indore</option>
          <option value="pune">Pune</option>
          <option value="allindia">ALL India</option>
          <option value="hyderabad">Hyderabad</option>
          <option value="mumbai">Mumbai</option>
          <option value="kanpur">Kanpur</option>
          <option value="chennai">Chennai</option>
          <option value="jammu">Jammu</option>
          <option value="lucknow">Lucknow</option>
          <option value="chandigarh">Chandigarh</option>
          <option value="hyderabad">Hyderabad</option>
        </select>

        <select
          value={categoryFilter}
          onChange={(e) => setCategoryFilter(e.target.value)}
        >
          <option value="">Select Category</option>
          <option value="cakes">Cakes</option>
          <option value="candlelightdinner">Candlelight Dinner</option>
          <option value="flowers">Flowers</option>
          <option value="decorations">Decorations</option>
          <option value="anniversarycelebrations">
            Anniversary Celebrations
          </option>
          <option value="birthdaysurprises">Birthday Surprises</option>
          <option value="kidscelebrations">Kids Celebrations</option>
          <option value="roomdecorations">Room Decorations</option>
          <option value="giftsandsurprises">Gifts And Surprises</option>
          <option value="occasions">Occasions</option>
        </select>

        <Box mt="10px" mb="10px">
          <Button mr={"10px"} bg="green.300" onClick={handleFilter}>
            Apply
          </Button>
          <Button bg="red.200" onClick={handle_reset_filter}>Reset</Button>
        </Box>
      </Box>
      <Text mb="-3px" color={"gray"} fontSize={"14px"} fontWeight={"bold"}>
        All products:- {allproducts.length}
      </Text>
      <TableContainer style={{ minWidth: "100%" }}>
        <Table variant="striped" size={"sm"}>
          <Thead bgColor={"tomato"}>
            <Tr>
              {keys.map((el) => {
                return <Th color="white">{el}</Th>;
              })}
            </Tr>
          </Thead>
          {loading ? (
            <Spinner textAlign={"center"} mt="10%" ml={"30rem"} size="xl" />
          ) : allproducts.length === 0 ? (
            <Text
              fontWeight={"600"}
              fontSize={"18px"}
              textAlign="center"
              mt="10%"
              ml={"23rem"}
            >
              Sorry no data Available..
            </Text>
          ) : (
            <Tbody>
              {currentProducts?.map((item) => (
                <Tr key={item._id}>
                  <Td>
                    <img
                      style={{ height: "50px", width: "50px" }}
                      src={item.image[0]}
                      alt=""
                    />
                  </Td>
                  <Td minW={"20px"}>
                    <Showmoredetail
                      name={item.name}
                      id={item._id}
                      data={allproducts}
                    />
                  </Td>
                  <Td>{item.category}</Td>
                  <Td>{item.city}</Td>
                  <Td>{item.price}</Td>
                  <Td>
                    <EditModal id={item?._id} products={allproducts} />
                  </Td>
                  <Td>
                    <Button
                      backgroundColor={"white"}
                      onClick={() => handle_delete_product(item._id)}
                    >
                      <DeleteIcon fontSize={"20px"} color={"red"} />
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button>
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
        </Button>
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i}
            onClick={() => paginate(i + 1)}
            style={{
              margin: "5px",
              fontWeight: i + 1 === currentPage ? "bold" : "normal",
            }}
          >
            {i + 1}
          </button>
        ))}
        <Button>
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </Button>
      </div>
    </div>
  );
};

export default TableCompProducts;
