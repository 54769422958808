import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  Image,
  Flex,
  Box,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  handle_delete_users_by_admin,
  handle_get_all_users,
  handlegetproducts,
} from "../../Redux/action";
import UserInfo from "./UserInfo";

const Showmoreorder = ({ name, id, data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");
  const [singled, setSingleData] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    const single = data.find((el) => el._id === id);

    if (single) {
      setSingleData(single);
      setUserId(single.userID);
    }
  }, [id, data]);

  useEffect(() => {
    dispatch(handle_get_all_users()).then((res) => {
      setUsers(res?.data?.data);
    });
  }, []);
  const userfind = users.find((el) => el._id === userId);

  // console.log("userfind", singled);

  return (
    <>
      <Text
        fontWeight="500"
        
        cursor="pointer"
        _hover={{ color: "tomato" }}
        width="auto"
        onClick={() => setIsOpen(true)}
      >
        {name}
      </Text>

      <Modal size={"xl"} isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>All Details</ModalHeader>
          <Flex
            mt="-10%"
            padding={"10px"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {singled &&
              singled.image.map((el, index) => (
                <Flex
                  alignItems={"center"}
                  key={index}
                  style={{
                    position: "relative",
                  }}
                >
                  <img
                    src={el}
                    style={{
                      width: "100px",
                      height: "70px",
                      margin: "5px",
                      transition: "transform 0.3s",
                      cursor: "pointer",
                      zIndex: 1,
                      position: "relative",
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.transform = "scale(5.1)")
                    }
                    onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
                  />
                </Flex>
              ))}
          </Flex>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {singled && (
              <Table variant="striped" size="sm" colorScheme="gray">
                <Tbody fontWeight="500" fontSize="14px">
                  <Tr>
                    <Td>More Details of User</Td>
                    <Td>
                      <UserInfo data={userfind} />
                    </Td>
                  </Tr>
                  {Object.entries(singled).map(([key, value]) => (
                    <Tr key={key}>
                      <Td>{key}:</Td>
                      {key === "orderDate" ? (
                        <Td>
                          {value.length > 0 ? (
                            <Box bg={"pink.100"} padding="10px">
                              <span style={{ fontWeight: "bold" }}>
                                {" "}
                                Date: {value[0].date}
                              </span>
                              <br />
                              <span
                                style={{ color: "red", fontWeight: "bold" }}
                              >
                                {" "}
                                Time: {value[0].slot.starttime}{" "}
                                {value[0].slot.starttime_words} to{" "}
                                {value[0].slot.endtime}{" "}
                                {value[0].slot.endtime_words}
                              </span>
                            </Box>
                          ) : (
                            <span style={{ color: "red" }}>
                              No order date available
                            </span>
                          )}
                        </Td>
                      ) : key === "addons" ? (
                        <Td>
                          <ul>
                            {value.map((addon, index) => (
                              <li key={index}>
                                {addon.name} - {addon.price} - {addon.desc}
                              </li>
                            ))}
                          </ul>
                        </Td>
                      ) : key === "multiple_price" ? (
                        <Td>
                          <ul>
                            {value.map((el, index) => (
                              <li key={index}>
                                {el.weight}Kg - ₹.{el.price}
                              </li>
                            ))}
                          </ul>
                        </Td>
                      ) : key === "description" ? (
                        <Td>
                          <ul>
                            {value.map((desc, index) => (
                              <li key={index}>{desc}</li>
                            ))}
                          </ul>
                        </Td>
                      ) : key === "address" ? (
                        <Td color={"tomato"} bg={"tomato.100"}>
                          <Box lineHeight={"18px"} bg={"pink.100"} padding="8px" color={"black"} fontSize={"15px"} fontWeight={"500"}>
                            {value}
                          </Box>
                        </Td>
                      ) : (
                        <Td>
                          {Array.isArray(value) ? value.join(", ") : value}
                        </Td>
                      )}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Showmoreorder;
