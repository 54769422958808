import React from 'react'
import TableComp from '../Tables/TableCompProducts'

const AdminDash = () => {
  return (
    <div>
      AdminDash helo
    </div>
  )
}

export default AdminDash