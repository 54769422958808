import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handle_edit_orders_by_admin,
  handle_get_allorders_byadmin,
} from "../../Redux/action";
import EditModal from "../AdminComponents/ProductEditModal";
import Address from "./Address";
import "./Orders.css";
import {
  Button,
  Table,
  TableContainer,
  Td,
  Th,
  Text,
  Thead,
  Box,
  Tr,
  Tbody,
  Select,
} from "@chakra-ui/react";
import jsPDF from "jspdf";
import CustomPagination from "./CustomPagination";
import Showmoreorder from "./Showmoreorder";
import Addresspage from "./Addresspage";

const Orders = () => {
  const itemsPerPage = 10;
  let keys = [
    "Select",
    "Image",
    "Name",
    "Order_Date",
    "Address",
    "Edit",
    "Status",
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const store = useSelector((store) => store);
  const [orders, setOrders] = useState([]);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handle_get_allorders_byadmin()).then((res) => {
      const sortedOrders = res.data.data
        .sort((a, b) => {
          return new Date(b.booked_dates[0]) - new Date(a.booked_dates[0]);
        })
        .reverse();

      setOrders(sortedOrders);
    });
  }, []);

  const totalItems = orders.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return orders.slice(startIndex, endIndex);
  };

  const handle_status_change = (id, data, val) => {
    data = { ...data, status: val };
    dispatch(handle_edit_orders_by_admin(id, data)).then((res) => {
      if (res.status === 200 || res.status === 201) {
        alert("Status changed");
      } else {
        alert("error");
      }
    });
  };

  function getStatusColor(status) {
    switch (status) {
      case "Pending":
        return "yellow";
      case "Delivered":
        return "green";
      case "Cancel":
        return "red";
      default:
        return "yellow";
    }
  }

  // State to keep track of selected orders
  const [selectedOrders, setSelectedOrders] = useState([]);

  // Function to handle selecting orders
  const handleOrderSelection = (orderId) => {
    const index = selectedOrders.indexOf(orderId);

    if (index > -1) {
      const updatedOrders = [...selectedOrders];
      updatedOrders.splice(index, 1);
      setSelectedOrders(updatedOrders);
    } else {
      setSelectedOrders([...selectedOrders, orderId]);
    }
  };

  // Function to handle PDF generation
  const handleDownloadPDF = () => {
    const selectedOrdersData = orders.filter((order) =>
      selectedOrders.includes(order._id)
    );

    const doc = new jsPDF();

    let posY = 20;

    // selectedOrdersData.forEach((order) => {
    //   doc.setFontSize(12);
    //   doc.text(15, posY, `Order: ${order.name}`);
    //   doc.text(15, posY + 15, `Date: ${order.orderDate[0].date}`);
    //   doc.text(
    //     25,
    //     posY + 30,
    //     `Time Slot: ${order.orderDate[0].slot.starttime} ${order.orderDate[0].slot.starttime_words} - ${order.orderDate[0].slot.endtime} ${order.orderDate[0].slot.endtime_words}`
    //   );
    //      doc.text(15, posY + 20, `${order.address}`);
    //   posY += 50;
    // });
    //  const doc = new jsPDF();
    //   let posY = 20;

    selectedOrdersData.forEach((order) => {
      // Product Information Section
      doc.setFontSize(14);
      doc.text(15, posY, "Product Information");
      doc.setFontSize(12);
      doc.text(15, posY + 10, `Order: ${order.name}`);
      doc.text(15, posY + 20, `Date: ${order.orderDate[0].date}`);
      doc.text(
        25,
        posY + 30,
        `Time Slot: ${order.orderDate[0].slot.starttime} ${order.orderDate[0].slot.starttime_words} - ${order.orderDate[0].slot.endtime} ${order.orderDate[0].slot.endtime_words}`
      );

      posY += 50;

      // User Information Section
      doc.setFontSize(14);
      doc.text(15, posY, "User Information");
      doc.setFontSize(12);
      doc.text(15, posY + 10, "Address:");
      const splitAddress = doc.splitTextToSize(order.address, 170); // Split the address to fit within 170 units width
      doc.text(15, posY + 20, splitAddress);

      posY += 30;
    });

    doc.save("order_receipt.pdf");
  };
  console.log("orders", orders);
  return (
    <div>
      <Text mb="-3px" color={"gray"} fontSize={"14px"} fontWeight={"bold"}>
        All Orders: {totalItems}
      </Text>
      <Button onClick={handleDownloadPDF}>Download PDF</Button>
      <TableContainer style={{ minWidth: "100%" }}>
        <Table variant="striped" size={"sm"}>
          <Thead bg={"tomato"}>
            <Tr>
              {keys.map((el) => {
                return (
                  <Th color={"white"} key={el}>
                    {el}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {getPaginatedData().map((item) => (
              <Tr key={item._id}>
                <Td>
                  <input
                    type="checkbox"
                    onChange={() => handleOrderSelection(item._id)}
                    checked={selectedOrders.includes(item._id)}
                  />
                </Td>
                <Td>
                  <img
                    style={{ height: "50px", width: "50px" }}
                    src={item.image[0]}
                    alt=""
                  />
                </Td>
                <Td>
                  {" "}
                  <Showmoreorder name={item.name} data={orders} id={item._id} />
                </Td>
                <Td>
                  {item.orderDate && item.orderDate[0] ? (
                    <div>
                      <Text fontWeight={"500"}>
                        Date: {item.orderDate[0].date}
                      </Text>
                      <Text
                        mt="-5px"
                        color="red"
                        fontWeight={"600"}
                        fontSize={"15px"}
                      >
                        Time Slot: {item.orderDate[0].slot.starttime}{" "}
                        {item.orderDate[0].slot.starttime_words} -{" "}
                        {item.orderDate[0].slot.endtime}{" "}
                        {item.orderDate[0].slot.endtime_words}
                      </Text>
                    </div>
                  ) : (
                    "NA"
                  )}
                </Td>
                <Td>
                  {item?.address ? (
                    <Addresspage data={item?.address} />
                  ) : (
                    <Address id={item._id} data={orders} />
                  )}
                </Td>

                <Td>
                  <select
                    placeholder="Update Status Here"
                    onChange={(e) =>
                      handle_status_change(item._id, item, e.target.value)
                    }
                  >
                    <option value="Update Status Here">Change Status</option>
                    <option value="Pending">Pending</option>
                    <option value="Delivered">Delivered</option>
                    <option value="Cancel">Cancel</option>
                  </select>
                </Td>
                <Td>
                  <Button
                    style={{ backgroundColor: getStatusColor(item.status) }}
                  >
                    {item.status}
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <CustomPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Orders;
