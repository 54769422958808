import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,

  TableContainer,
  Text,
} from "@chakra-ui/react";
const UserInfo = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
//   console.log("data", data);
  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}
      <Td
        color={"blue"}
        textDecoration={"underline"}
        _hover={{ color: "red" }}
        cursor={"pointer"}
        fontWeight={"bold"}
        onClick={onOpen}
      >
        User_Info
      </Td>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={"2xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Login data details</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <TableContainer mt="-8%">
              <Table variant="striped" size="sm" colorScheme="gray">
                <Tbody>
                  <Tr>
                    <Td fontWeight={"600"}>Name:</Td>
                    <Td fontWeight={"bold"}>{data?.name}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight={"600"}>Email:</Td>
                    <Td fontWeight={"bold"}> {data?.email}</Td>
                  </Tr>
                  <Tr>
                    <Td fontWeight={"600"}>Address:</Td>
                    <Td color="tomato" fontSize={"15px"} fontWeight={"700"}>{data?.address}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserInfo;
