import React, { useState } from "react";
import "./login.css";
import Swal from "sweetalert2";
import { CSSTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import { handleLogin, handleSignup } from "../Redux/action";
import ReactGA from "react-ga4";
import { Hearts } from "react-loader-spinner";
import { Navigate, useNavigate ,Link } from "react-router-dom";
import Aws from "../Components/ProductComponents/Aws";
import { Spinner, Text } from "@chakra-ui/react";
import { registrationform } from "../utils/TrackEvent";

function Login() {
  const [showLogin, setShowLogin] = useState(true);
  const [username, setusername] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useSelector((store) => store);
  const { loading, error, token } = store;

  const handleregister = (e) => {
    e.preventDefault();
    let obj = {
      name: username,
      email: email,
      password: password,
    };
     if (typeof window.fbq !== "undefined") {
       window.fbq("track", "SubmitSignup");
     }
  
     ReactGA.event({
       category: "User Actions", // Customize the category as needed
       action: "Signup", // Action describes the event
       label: "Successful Signup", // You can specify additional context here
     });
    dispatch(handleSignup(obj)).then((res) => {
      // console.log(res.response.status);
      if (res.status === 200 || res.status === 201) {
        Swal.fire("", "Signup Successful", "success");
       
        return navigate("/");
      } else {
        Swal.fire(
          "Enter valid Email or User Already Exists",
          "couldn't signup",
          "error"
        );
        return navigate("/login");
      }
    });
  };

  const handlelogin = (e) => {
    e.preventDefault();
    let obj = {
      email: email,
      password: password,
    };
 if (typeof window.fbq !== "undefined") {
   window.fbq("track", "SubmitLogin");
    }
   ReactGA.event({
     category: "User Actions", // Customize the category as needed
     action: "Login", // Action describes the event
     label: "Successful Login", // You can specify additional context here
   });
    dispatch(handleLogin(obj))
      .then((res) => {
        // console.log(res.data)
        if (res.status === 201) {
          Swal.fire("", "You have successfully logged in", "success");
         
          
          return navigate("/");
        } else {
          Swal.fire("Email or Password Wrong", "couldn't log in", "error");
          return navigate("/login");
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  if (token) {
    return <Navigate to="/" />;
  }

  const handleToggle = () => {
    setShowLogin(!showLogin);
  };

  return (
    <div className="container">
      <CSSTransition
        in={showLogin}
        timeout={300}
        classNames="form-transition"
        unmountOnExit
      >
        <div className="form-container">
          <h2>Login</h2>
          <form onSubmit={handlelogin}>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setpassword(e.target.value)}
                required
              />
            </div>
            <button className="registerbtn" type="submit">
              {loading ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="sm"
                />
              ) : (
                "Login"
              )}
            </button>
            <p className="toggle-text">
              Don't have an account?{" "}
              <span className="toggle-link" onClick={handleToggle}>
                Sign Up
              </span>
            </p>
            <Link to="/forgot">
              <Text
                _hover={{ textDecoration: "underline", color: "blue" }}
                textAlign={"center"}
                color={"blue.600"}
                fontSize={"14px"}
                fontWeight={"500"}
              >
                Reset Password
              </Text>
            </Link>
          </form>
        </div>
      </CSSTransition>
      <CSSTransition
        in={!showLogin}
        timeout={300}
        classNames="form-transition"
        unmountOnExit
      >
        <div className="form-container">
          <h2>Sign Up</h2>
          <form onSubmit={handleregister}>
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setusername(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setpassword(e.target.value)}
                required
              />
            </div>
            <button className="registerbtn" type="submit">
              {loading ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="sm"
                />
              ) : (
                "Register"
              )}
            </button>
          </form>
          <p className="toggle-text">
            Already have an account?{" "}
            <span className="toggle-link link-" onClick={handleToggle}>
              Login
            </span>
          </p>
        </div>
      </CSSTransition>
    </div>
  );
}

export default Login;
