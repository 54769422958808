// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   handle_get_allorders,
//   handle_edit_orders_by_admin,
// } from "../Redux/action";
// import { Link } from "react-router-dom";
// import "../Styles/orders.css";
// import { Button, Text, useToast } from "@chakra-ui/react";

// export default function OrdersPage() {
//   //   const dispatch = useDispatch();
//   //   const store = useSelector((store) => store);
//   //   const { orders, error } = store;
//   //   const user = JSON.parse(localStorage.getItem("userdetails")) || "";
//   // const toast = useToast()
//   //   useEffect(() => {
//   //     dispatch(handle_get_allorders(user?._id));
//   //   }, [dispatch, user._id]);

//   //   const canCancelOrder = (data) => {
//   //     const dateString = data.booked_dates[0];
//   //     const [datePart, timePart] = dateString.split(" ");
//   //     const [day, month, year] = datePart.split("/");
//   //     const [hours, minutes, seconds] = timePart.split(":");
//   //     const providedDate = new Date(
//   //       year,
//   //       month - 1,
//   //       day,
//   //       hours,
//   //       minutes,
//   //       seconds
//   //     );
//   //     const currentTime = new Date();
//   //     const hoursDifference = Math.ceil(
//   //       Math.abs(currentTime - providedDate) / 3600000
//   //     );
//   //     return hoursDifference < 48;
//   //   };

//   //   const handleStatusChange = (id, data) => {
//   //     data = { ...data, status: "Cancel" };
//   //     if (canCancelOrder(data)) {
//   //       dispatch(handle_edit_orders_by_admin(id, data)).then((res) => {
//   //         console.log("res", res);
//   //         if (res.status === 200 || res.status === 201) {
//   //            toast({
//   //              title: "Product Canceled successfully!.",
//   //              description: "Success!",
//   //              status: "success",
//   //              position: "top",
//   //              duration: 3000,
//   //              isClosable: true,
//   //            });
//   //         } else {
//   //           alert("Error canceling the order.");
//   //         }
//   //       });
//   //     } else {
//   //        toast({
//   //          title: "Product can't be Canceled.",
//   //          description:
//   //            "Order can't be canceled as more than 48 hours have passed!.",
//   //          status: "error",
//   //          position: "top",
//   //          duration: 3000,
//   //          isClosable: true,
//   //        });
//   //     }
//   //   };

//   //   function getStatusColor(status) {
//   //     switch (status) {
//   //       case "Pending":
//   //         return "yellow";
//   //       case "Delivered":
//   //         return "green";
//   //       case "Cancel":
//   //         return "red";
//   //       default:
//   //         return "yellow";
//   //     }
//   //   }
//   // console.log("orders", orders)

//   const dispatch = useDispatch();
//   const store = useSelector((store) => store);
//   const { orders, error } = store;
//   const user = JSON.parse(localStorage.getItem("userdetails")) || "";
//   const toast = useToast();

//   const canCancelOrder = (data) => {
//     const dateString = data.booked_dates[0];
//     const [datePart, timePart] = dateString.split(" ");
//     const [day, month, year] = datePart.split("/");
//     const [hours, minutes, seconds] = timePart.split(":");
//     const providedDate = new Date(
//       year,
//       month - 1,
//       day,
//       hours,
//       minutes,
//       seconds
//     );
//     const currentTime = new Date();
//     const hoursDifference = Math.ceil(
//       Math.abs(currentTime - providedDate) / 3600000
//     );

//     if (data.category === "cakes") {
//       return hoursDifference < 24;
//     } else {
//       return hoursDifference < 48;
//     }
//   };

//   useEffect(() => {
//     dispatch(handle_get_allorders(user?._id));
//   }, [dispatch, user._id]);

//   const handleStatusChange = (id, data) => {
//     data = { ...data, status: "Cancel" };
//     if (canCancelOrder(data)) {
//       dispatch(handle_edit_orders_by_admin(id, data)).then((res) => {
//         if (res.status === 200 || res.status === 201) {
//           if (data.category === "cakes") {
//             toast({
//               title: "Cake Order Canceled successfully!",
//               description: "You can cancel cake orders within 24 hours.",
//               status: "success",
//               position: "top",
//               duration: 3000,
//               isClosable: true,
//             });
//           } else {
//             toast({
//               title: "Product Canceled successfully!",
//               description: "You can cancel non-cake orders within 48 hours.",
//               status: "success",
//               position: "top",
//               duration: 3000,
//               isClosable: true,
//             });
//           }
//         } else {
//           alert("Error canceling the order.");
//         }
//       });
//     } else {
//       if (data.category === "cakes") {
//         toast({
//           title: "Cake Order can't be Canceled.",
//           description: "You can't cancel cake orders after 24 hours.",
//           status: "error",
//           position: "top",
//           duration: 3000,
//           isClosable: true,
//         });
//       } else {
//         toast({
//           title: "Product can't be Canceled.",
//           description: "You can't cancel non-cake orders after 48 hours.",
//           status: "error",
//           position: "top",
//           duration: 3000,
//           isClosable: true,
//         });
//       }
//     }
//   };

//   function getStatusColor(status) {
//     switch (status) {
//       case "Pending":
//         return "yellow";
//       case "Delivered":
//         return "green";
//       case "Cancel":
//         return "red";
//       default:
//         return "yellow";
//     }
//   }
//   return (
//     <div id="orders-cont">
//       <h1>My Orders</h1>
//       {orders?.map((el) => {
//         return (
//           <div id="orders-card" key={el._id}>
//             <div>
//               <img id="orders-img" src={el.image[0]} alt="" />
//             </div>

//             <div>
//               <Text fontSize={"17px"} fontWeight={"700"}>
//                 {el.name}
//               </Text>
//               <div>
//                 <Text fontSize={"14px"}>{el?.booked_dates[0]}</Text>
//                 <h3>
//                   {el.multiple_price.length ? (
//                     <div>
//                       {el.multiple_price.map((priceObj, index) => (
//                         <div key={index}>
//                           Weight: {priceObj.weight} Kg - ₹{priceObj.price}
//                         </div>
//                       ))}
//                     </div>
//                   ) : (
//                     <Text color="tomato" fontSize={"16px"} fontWeight={"600"}>
//                       {" "}
//                       {`₹. ${el.price}`}
//                     </Text>
//                   )}
//                 </h3>
//               </div>
//             </div>

//             <div id="shipping-orders">
//               <Button style={{ backgroundColor: getStatusColor(el.status) }}>
//                 {el.status}
//               </Button>
//               {el.status !== "Delivered" && el.status !== "Cancel" && (
//                 <Button
//                   ml="20px"
//                   onClick={() => handleStatusChange(el._id, el)}
//                 >
//                   Cancel
//                 </Button>
//               )}
//             </div>
//           </div>
//         );
//       })}
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handle_get_allorders,
  handle_edit_orders_by_admin,
} from "../Redux/action";
import { Link } from "react-router-dom";
import "../Styles/orders.css";
import { Hearts } from "react-loader-spinner";
import { Button, Text, useToast, Flex, Box, Image } from "@chakra-ui/react";
import "../Styles/orders.css";
export default function OrdersPage() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const store = useSelector((store) => store);
  const { orders, error } = store;
  const user = JSON.parse(localStorage.getItem("userdetails")) || "";
  const toast = useToast();
  console.log("order", orders);
  const canCancelOrder = (data) => {
    const dateString = data.booked_dates[0];
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");
    const [hours, minutes, seconds] = timePart.split(":");
    const providedDate = new Date(
      year,
      month - 1,
      day,
      hours,
      minutes,
      seconds
    );
    const currentTime = new Date();
    const hoursDifference = Math.ceil(
      Math.abs(currentTime - providedDate) / 3600000
    );

    if (data.category === "cakes") {
      return hoursDifference < 24;
    } else {
      return hoursDifference < 48;
    }
  };

  useEffect(() => {
    dispatch(handle_get_allorders(user?._id));
    setIsLoading(false);
  }, [dispatch, user._id]);

  const handleStatusChange = (id, data) => {
    data = { ...data, status: "Cancel" };
    if (canCancelOrder(data)) {
      dispatch(handle_edit_orders_by_admin(id, data)).then((res) => {
        if (res.status === 200 || res.status === 201) {
          if (data.category === "cakes") {
            toast({
              title: "Cake Order Canceled successfully!",
              description: "You can cancel cake orders within 24 hours.",
              status: "success",
              position: "top",
              duration: 3000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Product Canceled successfully!",
              description: "You can cancel non-cake orders within 48 hours.",
              status: "success",
              position: "top",
              duration: 3000,
              isClosable: true,
            });
          }
        } else {
          alert("Error canceling the order.");
        }
      });
    } else {
      if (data.category === "cakes") {
        toast({
          title: "Cake Order can't be Canceled.",
          description: "You can't cancel cake orders after 24 hours.",
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Product can't be Canceled.",
          description: "You can't cancel non-cake orders after 48 hours.",
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  function getStatusColor(status) {
    switch (status) {
      case "Pending":
        return "yellow";
      case "Delivered":
        return "green";
      case "Cancel":
        return "red";
      default:
        return "yellow";
    }
  }
  return (
    <div id="orders-cont">
      <h1>My Orders</h1>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Hearts
            height="80"
            width="80"
            color="red"
            ariaLabel="hearts-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : orders.length === 0 ? (
        <div
          style={{
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="https://marketplaceleader.wawbizstores.com/assets/images/no_order1.png"
            alt="Empty Orders"
          />
        </div>
      ) : (
        orders
          ?.slice()
          .sort((a, b) => {
            const dateA = new Date(a.booked_dates[0]);
            const dateB = new Date(b.booked_dates[0]);
            return dateB - dateA;
          })
          .reverse()
          .map((el) => {
            return (
              <Flex
                padding={"20px"}
                alignItems={"center"}
                justifyContent={"space-between"}
                mt={"15px"}
                className="container_1"
              >
                <Flex
                  padding={"0px 10px 0px 10px"}
                  alignItems={"center"}
                  gap="10px"
                  className="container_2"
                >
                  <Box padding={"5px"}>
                    <Image w="150px" h="150px" src={el?.image[0]} />
                  </Box>
                  <Box fontFamily={"sans"} h="150px" padding={"5px"}>
                    <Text
                      cursor={"pointer"}
                      color={"tomato"}
                      fontSize={"18px"}
                      fontWeight={"600"}
                    >
                      {el?.name}
                    </Text>
                    <Flex
                      mt="-10px"
                      fontSize={"14px"}
                      fontWeight={"500"}
                      color="gray"
                      alignItems={"center"}
                      gap="10px"
                    >
                      <Text>{el?.category} </Text>
                      <Text>{el?.city} </Text>
                    </Flex>
                    <Flex
                      alignItems={"center"}
                      gap="10px"
                      fontSize={"14px"}
                      fontWeight={"500"}
                      color="gray"
                      mt="-10px"
                      className="container_4"
                    >
                      <Text className="container_5">
                        {`Date: ${el?.orderDate[0]?.date}`}{" "}
                      </Text>
                      <Text className="container_6">
                        Time: {el?.orderDate[0]?.slot?.starttime}{" "}
                        {el?.orderDate[0]?.slot?.starttime_words} -{" "}
                        {el?.orderDate[0]?.slot?.endtime}{" "}
                        {el?.orderDate[0]?.slot?.endtime_words}
                      </Text>
                    </Flex>
                    <Text
                      mt="-10px"
                      fontSize={"14px"}
                      fontWeight={"500"}
                      color="gray"
                    >
                      {el?.booked_dates[0]}
                    </Text>
                  </Box>
                </Flex>

                <Flex
                  mt="5px"
                  mb="5px"
                  direction={"column"}
                  alignItems={"center"}
                  className="container_3"
                >
                  <Text
                    color="tomato"
                    fontSize={"16px"}
                    fontWeight={"600"}
                    className="container_3a"
                    pb={"15px"}
                  >
                    {el.multiple_price.length ? (
                      <div>
                        {el.multiple_price.map((priceObj, index) => (
                          <div key={index}>
                            Weight: {priceObj.weight} Kg - ₹{priceObj.price}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <Text
                        className="container_3b"
                        color="tomato"
                        fontSize={"16px"}
                        fontWeight={"600"}
                      >
                        {" "}
                        {`₹. ${el.price}`}
                      </Text>
                    )}
                  </Text>
                  <Text
                    className="container_3c"
                    mt={"-35px"}
                    fontWeight={"500"}
                    fontSize={"13px"}
                    color="gray"
                  >{`quantity ${el.quantity}`}</Text>
                </Flex>
                <Flex alignItems={"center"} gap="15px">
                  <Button
                    style={{ backgroundColor: getStatusColor(el.status) }}
                  >
                    {el.status}
                  </Button>

                  {el.status !== "Delivered" && el.status !== "Cancel" && (
                    <Button onClick={() => handleStatusChange(el._id, el)}>
                      Cancel
                    </Button>
                  )}
                </Flex>
              </Flex>
            );
          })
      )}
    </div>
  );
}
