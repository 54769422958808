import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Flex,
  Th,
  Box,
  Td,
  TableContainer,
} from "@chakra-ui/react";

const Showmoredetail = ({ name, id, data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [singled, setSingleData] = useState(null);

  useEffect(() => {
    const single = data.find((el) => el._id === id);
    setSingleData(single);
  }, [id, data]);

  

  return (
    <>
      <Text
        fontWeight="500"
        mt="15px"
        cursor="pointer"
        _hover={{ color: "tomato" }}
        width="auto"
        onClick={() => setIsOpen(true)}
      >
        {name}
      </Text>

      <Modal size={"3xl"} isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>All Details</ModalHeader>
          <Flex
            mt="-10%"
            padding={"10px"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {singled &&
              singled.image.map((el, index) => (
                <Flex
                  alignItems={"center"}
                  key={index}
                  style={{
                    position: "relative",
                  }}
                >
                  <img
                    src={el}
                    style={{
                      width: "100px",
                      height: "70px",
                      margin: "5px",
                      transition: "transform 0.3s",
                      cursor: "pointer",
                      zIndex: 1,
                      position: "relative",
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.transform = "scale(5.1)")
                    }
                    onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
                  />
                </Flex>
              ))}
          </Flex>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <TableContainer>
              {singled && (
                <Table variant="striped" size="sm" colorScheme="gray">
                  <Tbody fontWeight="500" fontSize="14px">
                    {Object.entries(singled).map(([key, value]) => (
                      <Tr key={key}>
                        <Td>{key}:</Td>
                        {key === "addons" ? (
                          <Td>
                            <ul>
                              {value.map((addon, index) => (
                                <li key={index}>
                                  {addon.name} - {addon.price} - {addon.desc}
                                </li>
                              ))}
                            </ul>
                          </Td>
                        ) : key === "slots" ? (
                          <Td>
                            <ul>
                              {value.map((slot, index) => (
                                <li key={index}>
                                  {slot.starttime} {slot.starttime_words} to{" "}
                                  {slot.endtime} {slot.endtime_words}
                                </li>
                              ))}
                            </ul>
                          </Td>
                        ) : key === "multiple_price" ? (
                          <Td>
                            <ul>
                              {value.map((el, index) => (
                                <li key={index}>
                                  {el.weight}Kg - ₹.{el.price}
                                </li>
                              ))}
                            </ul>
                          </Td>
                        ) : (
                          <Td>
                            {Array.isArray(value) ? value.join(", ") : value}
                          </Td>
                        )}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Showmoredetail;
