export const GETPRODUCTS = "getproducts";
export const GETALLPRODUCTS="GETALLPRODUCTS"
export const GETCARTPRODUCTS = "getcartproducts";
export const ADDCARTPRODUCTS = "addcartproduct";
export const DELETECARTPRODUCTS = "deletecartproduct";
export const HANDLECARTQUANTITY = "handle/cart/product/quantity";
export const LOGIN = "login";
export const SIGNUP = "signup";
export const LOADING = "loading";
export const LOGOUT = "logout";
export const ERROR = "error";
export const ADDRESSUPDATE = "addressupdate";
export const DELETE_ALLCARTPRODUCTS = "delete/allcart/products";
export const ADD_ALLCARTPRODUCTS_TO_ORDERS = "add/allcartproducts/to/orders";
export const GET_ALL_ORDERS = "get/allorders";
export const GET_ALL_ORDERS_BY_ADMIN = "get/allorders/byadmin";
export const GET_ALL_USERS = "get/allusers";
export const ADD_REVIEWS = "add/reviews";
export const ADMIN_LOGIN = "admin/login";
export const ADMIN_REGISTER = "admin/register";
export const ERASE_ADMIN_TOKEN = "earse/admin/token";
export const HANDLE_ADD_PRODUCT = "add/product/byadmin2";
export const HANDLE_DELETE_PRODUCT = "delete/product/byadmin";
export const HANDLE_EDIT_PRODUCT = "edit/product/byadmin";
export const HANDLE_FILTER_PRODUCT = "filter/product/byadmin";

export const HANDLE_DELETE_USER = "delete/user/byadmin";
export const HANDLE_UPDATE_ORDERS = "update/orders/byadmin";
export const HANDLE_ADD_SLIDER_IMAGES = "add/sliderimages/byadmin";
export const HANDLE_ADD_TESTIMONIAL_IMAGES = "add/testimonialimages/byadmin";
export const HANDLE_DELETE_SLIDERS_IMAGES = "delete/slidersimages/byadmin";
export const HANDLE_DELETE_TESTIMONIALS_IMAGES = "delete/testimonialimages/byadmin";
export const HANDLE_ADD_BLOGS = "add/blog/byadmin";
export const HANDLE_DELETE_BLOGS = "delete/blog/byadmin";
export const HANDLE_EDIT_BLOGS = "edit/blog/byadmin";
export const HANDLE_GET_BLOGS = "get/blog/byadmin";

export const HANDLE_DELETE_CELEBRATE = "delete/celebrate/byadmin";
export const HANDLE_ADD_CELEBRATE = "add/celebrate/byadmin";
export const HANDLE_GET_CELEBRATE = "get/celebrate/byadmin";
// celebration 2 here 
export const HANDLE_DELETE_CELEBRATION = "delete/celebration/byadmin";
export const HANDLE_ADD_CELEBRATION = "add/celebration/byadmin";
export const HANDLE_GET_CELEBRATION = "get/celebration/byadmin";

export const HANDLE_GET_SLIDERS = "get/blog/byadmin";
export const HANDLE_GET_TESTIMONIALS = "get/blog/byadmin";
export const SETCITY = "setcity";