import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  handle_addcelebration_Image_by_admin,
  handle_getcelebration_Image_by_admin,
  handle_deletecelebration_Image_by_admin,
} from "../../Redux/action";
import {
  Button,
  Table,
  TableContainer,
  Td,
  Th,
  Thead,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Box,
  Tbody,
  Center,
  Select,
  Flex,
  Text,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
const Celebration = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [imageUrl, setImageUrl] = useState("");
  const [slider, setslider] = useState([]);
  const store = useSelector((store) => store);
  const dispatch = useDispatch();
  const { loading, error, allproducts } = store;

  useEffect(() => {
    dispatch(handle_getcelebration_Image_by_admin()).then((res) => {
      setslider(res?.data?.data);
    });
  }, []);

  const handleaddsliderImage = () => {
    let obj = {
      images: imageUrl,
      
    };
    console.log(obj);
    dispatch(handle_addcelebration_Image_by_admin(obj)).then((res) => {
      if (res.status == 200 || res.status == 201) {
        alert("Image Added");
      } else {
        alert("error");
      }
    });
    onClose();
  };
  const handlecelebaction = (id) => {
    dispatch(handle_deletecelebration_Image_by_admin(id)).then((res) => {
      if (res.status == 200 || res.status == 201) {
        alert("Image Deleted");
      } else {
        alert("error");
      }
    });
  };
  const key = ["IMAGE", "ID", "DELETE"];
  return (
    <>
      <Button
        float={"right"}
        colorScheme="teal"
        variant="solid"
        onClick={onOpen}
        mb="15px"
      >
        ADD IMAGES
      </Button>
      <TableContainer style={{ minWidth: "100%", overflow: "hidden" }}>
        <Table size={"sm"} variant={"striped"} colorScheme="pink">
          <Thead bg="tomato">
            <Tr>
              {key.map((el) => (
                <Th color="white">{el}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {slider?.map((item) => (
              <Tr key={item._id}>
                <Td>
                  <img
                    style={{ height: "20%", width: "20%" }}
                    src={item?.images}
                    alt=""
                  />
                </Td>

                <Td>{item?._id}</Td>
              

                <Td>
                  <Button
                    bg="none"
                    onClick={() => handlecelebaction(item?._id)}
                  >
                    <DeleteIcon color={"red"} fontSize={"16px"} />
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb="20px" mt="-4%">
              <Input
                variant="filled"
                placeholder="Add Image Url"
                onChange={(e) => setImageUrl(e.target.value)}
              />
              {/* <Center fontWeight={"bold"} fontSize={"14px"}>
                {" "}
                OR
              </Center>
              <Input type="file"   /> */}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => handleaddsliderImage()}
            >
              Add
            </Button>
            <Button bg="red.300" colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Celebration;
