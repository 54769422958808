import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@chakra-ui/react";
import "./carousel.css";
import { ArrowRightIcon, ArrowLeftIcon } from "@chakra-ui/icons";
const AsNavFor = () => {
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const params = useParams();
  const id = params.name;
  const [product, setProduct] = useState([]);
  const store = useSelector((store) => store);
  const { error, loading, products } = store;

  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    slider1.current = slider1.current;
    slider2.current = slider2.current;
  }, []);

  useEffect(() => {
    let findProduct = products?.filter((el) => {
      return el.name.replace(/ /g, "-") === id;
    });
    setProduct(findProduct);
  }, [products, id]);
  // console.log("products", product);
  const CustomPrevArrow = ({ onClick }) => (
    <div className="custom-prev-arrow" onClick={onClick}>
      <ArrowLeftIcon />
    </div>
  );

  const CustomNextArrow = ({ onClick }) => (
    <div className="custom-next-arrow" onClick={onClick}>
      <ArrowRightIcon />
    </div>
  );
  const settings = {
    slidesToShow: 1,
    infinite: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <div style={{ width: "100%", overflow: "hidden", height: "auto" }}>
      <Slider
        slidesToShow={1}
        swipeToSlide={true}
        focusOnSelect={true}
        asNavFor={slider2.current}
        ref={slider1}
        dots={true}
        infinite={true}
        className="slick_carousel"
        vertical={false}
      >
        {product[0]?.image?.map((el, index) => (
          <div key={index}>
            <img
              style={{ cursor: "pointer" }}
              width={"100%"}
              src={el}
              alt=""
              className="hover_img"
              onClick={() => openModal(el)}
            />
          </div>
        ))}
      </Slider>

      {selectedImage && (
        <div className="modal">
          <Slider {...settings} className="modal-slider">
            {product[0]?.image?.map((el, index) => (
              <div key={index}>
                {/* <div className="close" onClick={closeModal}>
                  {" "}
                  <span>X</span>
                </div> */}
                <img
                  src={el}
                  alt=""
                  onClick={closeModal}
                  className="modal_img"
                />
              </div>
            ))}
          </Slider>
        </div>
      )}

      <Slider
        slidesToShow={3}
        dots={true}
        arrows={true}
        infinite={true}
        adaptiveHeight={true}
        asNavFor={slider1.current}
        ref={slider2}
        className="slick_carousel"
        swipeToSlide={true}
        focusOnSelect={true}
        style={{ display: "flex" }}
      >
        {product[0]?.image?.map((el, index) => (
          <div key={index} style={{ border: "1px solid red" }}>
            <img
              style={{ cursor: "pointer", marginLeft: "10px" }}
              width={"100%"}
              src={el}
              alt=""
              className="hover_img1"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default AsNavFor;
