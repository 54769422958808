export const productpage = () => {
  if (typeof window.fbq !== "undefined") {
    window.fbq("track", "ProductPage");
  }
  
};

export const addtocart = () => {
  // window.fbq("track", "AddToCart");
  if (typeof window.fbq !== "undefined") {
    window.fbq("track", "AddToCart");
  }
};
export const checkoutpage = () => {
  // window.fbq("track", "InitiateCheckout");
  if (typeof window.fbq !== "undefined") {
    window.fbq("track", "InitiateCheckout");
  }
};

export const formsubmit = () => {
  // window.fbq("track", "Lead");
  if (typeof window.fbq !== "undefined") {
    window.fbq("track", "Lead");
  }
};
export const registrationform = () => {
  // window.fbq("track", "CompleteRegistration");
  if (typeof window.fbq !== "undefined") {
    window.fbq("track", "CompleteRegistration");
  }
};