import {
  Box,
  Center,
  Heading,
  Text,
  Button,
  Image,
  Flex,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const FailurePage = () => {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(timer);
        navigate("/checkout");
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [seconds, navigate]);

  return (
    <Center h="90vh">
      <Box
        p={8}
        bg="white"
        boxShadow="lg"
        rounded="lg"
        maxW="600px"
        w="90%"
        textAlign="center"
      >
        <Flex alignItems={"center"} justifyContent={"center"}>
          <Image
            src="https://i0.wp.com/balututorials.com/wp-content/uploads/2020/07/Error.gif"
            alt="Error"
            mb={6}
          />
        </Flex>
        <Box mt={"-15%"} textAlign={"center"}>
          <Heading color="red.500" size="lg">
            Payment Failed
          </Heading>
          <Text mt={4} textAlign={"center"}>
            Sorry, your payment was not successful. Please try again later.
          </Text>
          <Text mt={4} textAlign={"center"}>
            Redirecting in {seconds} seconds...
          </Text>
          <Button
            colorScheme="blue"
            variant="solid"
            size="md"
            mt={6}
            onClick={() => {
              navigate("/checkout");
            }}
          >
            Retry Payment
          </Button>
        </Box>
      </Box>
    </Center>
  );
};

export default FailurePage;
