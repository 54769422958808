import React, { useEffect, useState } from "react";
import "./celebrate.css";
import { handle_getcelebration_Image_by_admin } from "../../Redux/action";
import { useDispatch, useSelector } from "react-redux";
const Celebrate = () => {
  const [img, setimages] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handle_getcelebration_Image_by_admin()).then((res) => {
      setimages(res?.data?.data);
    });
  }, []);

  // let img = [
  //   {
  //     img: "https://valentinesagasssets.s3.amazonaws.com/Banner/celebration/WebsiteBanner2.webp",
  //   },
  //   {
  //     img: "https://valentinesagasssets.s3.amazonaws.com/Banner/celebration/WebsiteBanner.webp",
  //   },
  // ];

  return (
    <div style={{ marginTop: "30px" }}>
      <h1 style={{ marginBottom: "30px", marginTop: "30px" }}>
        CELEBRATE OCCASIONS WITH INDIA'S #1 ONLINE GIFT STORE
      </h1>
      <div id="celebrate-cont">
        {img.map((e) => {
          return <img key={e.id} src={e.images} alt="" />;
        })}
      </div>
    </div>
  );
};

export default Celebrate;
