import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'

const Singleorderpage = () => {
   const store = useSelector((store) => store);
  const params = useParams();
  const id = params.id
  console.log("id", id)
  
  useEffect(() => {
  
},[])


  return (
    <div>

    </div>
  )
}

export default Singleorderpage