import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "./DatePicker.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Select,
  Text,
  Radio,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import AddonsCard from "./AddonsCard";
import { useNavigate } from "react-router-dom";
import { addtocart } from "../../utils/TrackEvent";
export const DatePickerComp = ({
  isDatePickerEnabled,
  product,
  showDatePicker,
  handleByNow,
  update,
  handleaddtocart,
}) => {
  const today = new Date();
  today.setDate(today.getDate() + 1);
  const [startDate, setStartDate] = useState(today);
  const [isSlotModalOpen, setIsSlotModalOpen] = useState(false);
  const navigate = useNavigate();
  const [selectedSlotText, setSelectedSlotText] = useState("");
  const [selectedSlot, setSelectedSlot] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  let selectDate = `${startDate.getDate()}-${
    startDate.getMonth() + 1
  }-${startDate.getFullYear()}`;
  let obj = {
    date: selectDate,
  };
  const closeSlotModal = () => {
    setIsSlotModalOpen(false);
  };
  const openSlotModal = () => {
    setIsSlotModalOpen(true);
  };
  const handleSlotSelection = (slot) => {
    obj.slot = slot;
    setSelectedSlot(slot);
    localStorage.setItem("slotedate", JSON.stringify(obj));
  };

  const formatTimeSlot = (slot) => {
    if (!slot || !slot.starttime || !slot.endtime) {
      return "Invalid time slot";
    }

    const startTimeComponents = slot.starttime.split(":");
    const startHour = startTimeComponents[0].padStart(2, "0");
    const startMinutes = startTimeComponents[1] || "00";
    const startTime = `${startHour}:${startMinutes} ${slot.starttime_words}`;

    const endTimeComponents = slot.endtime.split(":");
    const endHour = endTimeComponents[0].padStart(2, "0");
    const endMinutes = endTimeComponents[1] || "00";
    const endTime = `${endHour}:${endMinutes} ${slot.endtime_words}`;

    return `${startTime} to ${endTime}`;
  };

  const handleAddToCart = () => {};
  useEffect(() => {
    if (showDatePicker) {
      onOpen();
    }
    addtocart();
    obj.date = selectDate;
  }, [update]);

  const handleDateChange = (date) => {
    setStartDate(date);
    handleByNow();
  };

  return (
    <>
      <DatePicker
        selected={startDate}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
        withPortal
        minDate={startDate}
        disabled={
          product[0]?.category !== "candlelightdinner" &&
          product[0]?.category !== "roomdecorations" &&
          !isDatePickerEnabled
        }
        className={
          product[0]?.category !== "candlelightdinner" &&
          product[0]?.category !== "roomdecorations" &&
          !isDatePickerEnabled
            ? "disabled-date-picker"
            : ""
        }
      />
      {showDatePicker && (
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Select Slot</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                {product[0]?.slots?.map((slot, index) => (
                  <Box
                    key={index}
                    className={`${
                      selectedSlot === slot ? "radiobgcolor" : ""
                    } slotBox`}
                    onClick={() => handleSlotSelection(slot)}
                  >
                    <Radio
                      size="md"
                      isChecked={selectedSlot === slot}
                      colorScheme="rgb(255, 65, 139)"
                    >
                      <Text ml={"20px"}>{formatTimeSlot(slot)}</Text>
                    </Radio>
                  </Box>
                ))}
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                onClick={() => {
                  onClose();
                  handleaddtocart();
                  navigate("/cart");
                }}
              >
                Book Now
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
