import React, { useRef, useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Text,
  Td,
  Flex,
  Select,
  Button,
  useToast,
  Heading,
} from "@chakra-ui/react";
import {
  handle_get_allorders_byadmin,
  handle_edit_orders_by_admin,
} from "../../Redux/action";
import { useDispatch } from "react-redux";
import Showmoreorder from "../Tables/Showmoreorder";

const NotificationsOrders = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [orders, setOrders] = useState([]);
  const [isNewOrder, setIsNewOrder] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const dispatch = useDispatch();
  const toast = useToast();
  useEffect(() => {
    dispatch(handle_get_allorders_byadmin()).then((res) => {
      const sortedOrders = res.data.data
        .sort(
          (a, b) => new Date(b.booked_dates[0]) - new Date(a.booked_dates[0])
        )
        .reverse();

      const pendingOrders = sortedOrders.filter(
        (order) => order.status === "Pending"
      );

      const isNew = pendingOrders.some((order) => {
        const orderDate = new Date(order.booked_dates[0]);
        const currentTime = new Date();

        return (currentTime - orderDate) / (1000 * 60 * 60) <= 24;
      });
      setOrders(pendingOrders);
      setIsNewOrder(isNew);
    });
  }, [dispatch]);

  const handle_status_change = (id, data, val) => {
    data = { ...data, status: val };
    dispatch(handle_edit_orders_by_admin(id, data)).then((res) => {
      if (res.status === 200 || res.status === 201) {
        toast({
          title: "Status Changed successfully",
          description: "Status changed successfully check it !",
          status: "success",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Status unsuccessfull",
          description: "Status not changed check it !",
          status: "error",
          position: "top",
          duration: 3000,
          isClosable: true,
        });
      }
    });
  };

  function getStatusColor(status) {
    switch (status) {
      case "Pending":
        return "yellow";
      case "Delivered":
        return "green";
      case "Cancel":
        return "red";
      default:
        return "yellow";
    }
  }
  return (
    <Box>
      <a onClick={onOpen} className="notification">
        <i className="bx bxs-bell"></i>
        {isNewOrder && <span className="new-tag">New</span>}{" "}
        <span className="num">{orders.length}</span>
      </a>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={"3xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Orders</ModalHeader>
          <ModalCloseButton />
          <ModalBody mt="-8%">
            {orders.length === 0 ? (
              <Heading size="sm" textAlign={"center"}>No orders available</Heading>
            ) : (
              orders.map((order) => (
                <Box
                  style={{
                    backgroundColor:
                      selectedProductId === order._id ? "white" : "lightgreen",
                  }}
                  onClick={() => setSelectedProductId(order._id)}
                  boxShadow={
                    "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px"
                  }
                  padding="5px"
                  mt="10px"
                >
                  <Flex alignItems={"center"} gap="10px">
                    <img
                      style={{ width: "100px", height: "100px" }}
                      src={order.image[0]}
                      alt="alt"
                    />
                    <Box>
                      <Flex
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        gap="10px"
                      >
                        <Text fontWeight={"bold"} color={"tomato"}>
                          <Showmoreorder
                            name={order.name}
                            data={orders}
                            id={order._id}
                          />
                        </Text>
                        <Flex alignItems={"center"} gap="10px">
                          <Select
                            onChange={(e) =>
                              handle_status_change(
                                order._id,
                                order,
                                e.target.value
                              )
                            }
                            variant="filled"
                            bg="green.100"
                          >
                            <option value=" ">Change Status</option>
                            <option value="Pending">Pending</option>
                            <option value="Delivered">Delivered</option>
                            <option value="Cancel">Cancel</option>
                          </Select>
                          <Button bg={getStatusColor(order.status)}>
                            {order.status}
                          </Button>
                        </Flex>
                      </Flex>
                      <Box mt="-25px">
                        {order.orderDate && order.orderDate[0] ? (
                          <Flex alignItems={"center"} gap="20px">
                            <Text fontWeight={"500"}>
                              {`Date: ${order.orderDate[0].date}`}
                            </Text>
                            <Text
                              fontStyle={"italic"}
                              color="red"
                              fontWeight={"500"}
                            >
                              Time: {order.orderDate[0].slot.starttime}{" "}
                              {order.orderDate[0].slot.starttime_words} -{" "}
                              {order.orderDate[0].slot.endtime}{" "}
                              {order.orderDate[0].slot.endtime_words}
                            </Text>
                          </Flex>
                        ) : (
                          "NA"
                        )}
                      </Box>
                      <Flex gap="10px" mt="-15px">
                        <span style={{ color: "tomato", fontWeight: "600" }}>
                          Address:
                        </span>{" "}
                        <Text fontWeight={"500"} fontStyle={"italic"}>
                          {order.address}
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
              ))
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default NotificationsOrders;
